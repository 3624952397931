import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import CreateIcon from '@mui/icons-material/Create'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Paper from '@mui/material/Paper'
import CurrentUserAvatar from './CurrentUserAvatar'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import UserAuthContainer from '../Utils/UserAuthContainer'

const LoginMenuItem = ({ handleLoginClick }) => (
  <MenuItem onClick={handleLoginClick}>
    <ListItemIcon>
      <HowToRegIcon />
    </ListItemIcon>
    <ListItemText primary="Login" />
  </MenuItem>
)

const MyProfileMenuItem = React.forwardRef((props, ref) => (
  <MenuItem ref={ref} onClick={props.handleProfileClick}>
    <ListItemIcon>
      <CreateIcon />
    </ListItemIcon>
    <ListItemText primary="My Profile" />
  </MenuItem>
))

const SignupMenuItem = ({ handleSignupClick }) => (
  <MenuItem onClick={handleSignupClick}>
    <ListItemIcon>
      <PersonAddIcon />
    </ListItemIcon>
    <ListItemText primary="Sign up" />
  </MenuItem>
)

const LogoutMenuItem = ({ handleLogoutClick }) => (
  <MenuItem onClick={() => handleLogoutClick()}>
    <ListItemIcon>
      <AccountCircle />
    </ListItemIcon>
    <ListItemText primary="Logout" />
  </MenuItem>
)

const AccountMenu = () => {
  const userAuthContainer = UserAuthContainer.useContainer()

  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const showLoginMenuItem = () => !userAuthContainer.isAuthed() || userAuthContainer.isGuestAuthed()
  const showLogoutMenuItem = () => userAuthContainer.isAuthed()
  const showSignupMenuItem = () => !userAuthContainer.isAuthed() || userAuthContainer.isGuestAuthed()
  const showProfileMenuItem = () => userAuthContainer.isRegisteredAuthed()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const clearAndCloseHandler = async (path) => {
    handleClose();
    await userAuthContainer.clearUserAndToken();
    navigate(path);
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <CurrentUserAvatar />
      </IconButton>
      <Paper>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {showProfileMenuItem() && <MyProfileMenuItem handleProfileClick={() => clearAndCloseHandler("/profile")} />}
          {showLoginMenuItem() && <LoginMenuItem handleLoginClick={() => clearAndCloseHandler("/login")} />}
          {showSignupMenuItem() && <SignupMenuItem handleSignupClick={() => clearAndCloseHandler("/signup")} />}
          {showLogoutMenuItem() && <LogoutMenuItem handleLogoutClick={() => clearAndCloseHandler("/login")} />}
        </Menu>
      </Paper>
    </>
  )
}

export default AccountMenu
