import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import EventRoute from './EventRoute'
import HomePage from './components/Pages/HomePage'
import ScanPage from './components/Pages/ScanPage'
import AboutPage from './components/Pages/AboutPage'
import LoginPage from './components/Pages/LoginPage'
import SignupPage from './components/Pages/SignupPage'
import EventsPage from './components/Pages/EventsPage'
import AppsPage from './components/Pages/AppsPage'
import EventPage from './components/Pages/EventPage'
import CreateEventPage from './components/Pages/CreateEventPage'
import EventParticipantPage from './components/Pages/EventParticipantPage'
import ViewProfilePage from './components/Pages/ViewProfilePage'
import UpdateProfilePage from './components/Pages/UpdateProfilePage'
import NearbyPage from './components/Pages/NearbyPage'
import ChatPage from './components/Pages/ChatPage'

const AnearRoutes = () => {
  return (
    <Routes>
      <Route path={"/"}               element={<HomePage />} />
      <Route path={"/scan"}           element={<ScanPage />} />
      <Route path={"/about"}          element={<AboutPage />} />
      <Route path={"/login"}          element={<LoginPage />} />
      <Route path={"/signup"}         element={<SignupPage />} />

      <Route path={"/apps"}           element={<AuthenticatedRoute element={<AppsPage />} />} />
      <Route path={"/c/:zoneId"}      element={<AuthenticatedRoute element={<CreateEventPage />} />} />
      <Route path={"/p/:eventId"}     element={<AuthenticatedRoute element={<EventParticipantPage />} />} />
      <Route path={"/profile"}        element={<AuthenticatedRoute element={<ViewProfilePage />} />} />
      <Route path={"/update_profile"} element={<AuthenticatedRoute element={<UpdateProfilePage />} />} />
      <Route path={"/chat"}           element={<AuthenticatedRoute element={<ChatPage />} />} />

      <Route path={"/events"}         element={<EventRoute element={<EventsPage />} />} />
      <Route path={"/e/:eventSlug"}   element={<EventRoute element={<EventPage />} />} />
      <Route path={"/nearby"}         element={<EventRoute element={<NearbyPage />} />} />
    </Routes>
  )
}

export default AnearRoutes
