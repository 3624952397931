import { createTheme } from '@mui/material/styles'
import { lightBlue, deepPurple } from '@mui/material/colors'

const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: lightBlue[500] // Specify the main color explicitly
    },
    secondary: {
      main: deepPurple[500] // Specify the main color explicitly
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '6px'
        }
      }
    }
  }
})

export default DefaultTheme

