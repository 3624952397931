import Playable from './Playable'

class PlayableZone extends Playable {

  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.zone = this.findIncluded(this.relationships['zone'])
    this.app = this.findIncluded(this.zone.relationships['app'])
  }

  get maxParticipationRadius() {
    return this.zone.attributes['max-participation-radius']
  }

  eventRequiresGeoLocation() {
    return false // how will this be set in the Anear API Zone ?
  }
}

export default PlayableZone
