import * as logger from 'loglevel'
import React, {useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Card, Box, CardContent } from '@mui/material'

import EventFrame from './EventFrame'
import anearApi from '../Api/AnearApi'

import EventsContainer from '../Utils/EventsContainer'
import UIStateContainer from '../Utils/UIStateContainer'
import UserAuthContainer from '../Utils/UserAuthContainer'

const EventPage = () => {
  const eventsContainer = EventsContainer.useContainer()
  const uiStateContainer = UIStateContainer.useContainer()
  const userAuthContainer = UserAuthContainer.useContainer()

  const { eventSlug } = useParams()
  const navigate = useNavigate()

  const anearEvent = eventsContainer.getEventFromSlug(eventSlug)

  const eventId = useState(anearEvent?.id)[0]
  const setError = useState(null)[1]
  const setBroadcastMessages = useState([])[1]

  const eventState = eventsContainer.getEventState(eventId)

  const clickHandlers = async (e, command) => {
    await browserClickHandlers(e, command, eventState)
  }

  useEffect(() => {
    logger.info(`event ${eventId} componentDidMount`)

    const broadcastMessageHandler = message => {
      // message.name => 'broadcast'
      // message.data => {content: <message string>}
      setBroadcastMessages((prevBroadcastMessages) => [
        ...prevBroadcastMessages,
        message.data
      ])
    }

    const eventTransitionHandler = async message => {
      const newState = message.data.content.state

      logger.info(`Event '${eventId}' state transition change to ${newState} via app`)
      // reload the current page to pick up latest state and user context
      // from the API server
      if (!eventsContainer.needsEventRefresh(eventId, newState)) return

      await eventsContainer.reloadEventWithProgress(eventId)

      const anearEvent = eventsContainer.getEvent(eventId)
      const eventState = eventsContainer.getEventState(eventId)
      if (eventState.isPlayAgainInvitable()) {
        anearEvent.subscribeEventPlayAgainMessages(invitedToPlayAgainHandler)
      }
    }

    const invitedToPlayAgainHandler = async (message) => {
      const eventId = message.data.content.event_id
      const anearEvent = eventsContainer.getEvent(eventId)

      if (!anearEvent) return

      anearEvent.unSubscribeEventPlayAgainMessages(invitedToPlayAgainHandler)

      const eventState = eventsContainer.getEventState(eventId)

      if (eventState.isParticipant()) {

        await eventsContainer.removeParticipant(eventId)

        //
        // build up a route to the EventParticipantPage from
        // the content in the message
        //
        logger.info(`received request to play again in event ${eventId}.`)

        navigate(`/p/${eventId}`, { state: { playAgain: true } })

      } else {
        logger.info(`spectator view play_again event ${eventId}.`)

        navigate(anearEvent.short_url)
      }
    }

    const cleanup = async (anearEvent) => {
      if (!anearEvent) return

      logger.info(`event ${anearEvent.id} unmounting.`)

      anearEvent.unSubscribeEventBroadcastMessages()
      anearEvent.unSubscribeEventTransitionMessages()
      anearEvent.unSubscribeEventPlayAgainMessages()

      const participant = eventsContainer.getParticipantForEvent(anearEvent.id)

      if (participant) {
        participant.unSubscribeEventParticipantsMessages()
        participant.unSubscribeEventPrivateMessages()
        participant.unSubscribeEventCssMessages()
        await participant.closeMessaging()
      } else if (anearEvent.spectatorsAllowed()) {
        anearEvent.closeSpectatorsChannel()
      }

      await eventsContainer.removeEvent(anearEvent.id)
    }
    if (!anearEvent) return

    if (eventState.isInProgress()) {
      anearEvent.subscribeEventBroadcastMessages(broadcastMessageHandler)
      anearEvent.subscribeEventTransitionMessages(eventTransitionHandler)
    }

    return async () => { logger.info(`event ${anearEvent.id} unmounting.`)
      await cleanup(anearEvent)
    }
  }, [uiStateContainer, eventsContainer, eventId, navigate, anearEvent, eventState, setBroadcastMessages])

  const requestPlayAgainHandler = async () => {
    const anearEvent = eventsContainer.getEvent(eventId)

    logger.info(`request to play event ${eventId} again`)

    //
    // This client is creating the play again event, so no need to
    // process any triggered invitation to play
    //
    await eventsContainer.removeParticipant(eventId)

    uiStateContainer.startProgress()

    try {
      const clonedEvent = await anearApi.cloneEvent(anearEvent)
      await cloneEventSuccess(clonedEvent)
    } catch(error) {
      cloneEventFailure(error)
    }
  }

  const cloneEventSuccess = async clonedEvent => {
    logger.info(`clone event success for ${clonedEvent.id}!`)

    eventsContainer.addNewEvent(clonedEvent)

    try {
      if (!clonedEvent.hosted) {
        //
        // if not a hosted event, the event creator will automatically
        // become a participant
        //
        const anearParticipant = await anearApi.becomeEventParticipant(clonedEvent)
        await eventsContainer.addNewParticipant(anearParticipant)
      }
      await uiStateContainer.progressComplete()
      navigate(clonedEvent.short_url, { replace: true })
    } catch(err) {
      await uiStateContainer.progressComplete()
      cloneEventFailure(err)
    }
  }

  const cloneEventFailure = error => {
    // flash a message in the outline red
    logger.error(error)
    setError(error)
  }

  const stopParticipating = async () => {
    const anearParticipant = eventsContainer.getParticipantForEvent(eventId)
    if (anearParticipant) await anearParticipant.publishExitEvent()
  }

  const cancelClickHandler = async eventState => {
    if (eventState.isCreator() || eventState.isParticipant()) {
      await stopParticipating()
      if (eventState.isCreator() && eventState.isOpening()) {
        await eventsContainer.removeEventAndParticipant(eventId)
      } else {
        await eventsContainer.removeParticipant(eventId)
      }
    }
    navigate("/apps", { replace: true })
  }

  const eventParticipantFailure = error => {
    // flash a message in the outline red
    logger.error(error)
    setError(error)
  }

  const eventParticipantHandler = async () => {
    uiStateContainer.startProgress()

    try {
      const anearEvent = eventsContainer.getEvent(eventId)
      // TODO: This becomeEventParticipant call may fail if another player has
      // already claimed the last spot in a two player game, or if the
      // Event State is not Announce. If this fails, the player should see Event
      // already started message and remain a spectator
      const anearParticipant = await anearApi.becomeEventParticipant(anearEvent)
      await eventsContainer.addNewParticipant(anearParticipant)

      await uiStateContainer.progressComplete()
    } catch(errMsg) {
      await uiStateContainer.progressComplete()
      eventParticipantFailure(errMsg)
    }
  }

  const browserClickHandlers = async (e, action, eventState=null) => {
    switch(action) {
      case "cancel":
      case "back":
        cancelClickHandler(eventState)
        break
      case "replay":
        await requestPlayAgainHandler()
        break
      case "join":
        await eventParticipantHandler()
        break
      default:
        throw new Error(`unknown browserClick command=${action}`)
    }
  }

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        alignItems: 'center',
        flexDirection: 'column',
        px: 1,
        py: 1
      }}
    >

      {anearEvent && (
        <Card
          sx={{
            zIndex: 1,
            position: 'relative',
            m: 1
          }}
        >
          <CardContent
            sx={{
              p: 1
            }}
          >
            <EventFrame
              eventsContainer={eventsContainer}
              userAuthContainer={userAuthContainer}
              anearEvent={anearEvent}
              clickHandlers={clickHandlers}
            />
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

export default EventPage
