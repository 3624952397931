import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'

const QrButtonIconPath = `${process.env.REACT_APP_ANEARAPP_ASSETS_URL}qr_code_icon_scan_button_2.png`

const ScanQrButton = _ => {
  const navigate = useNavigate()

  const handleClick = _e => {
    navigate("/scan")
  }

  return (
    <Button
      sx={{
        p: 1,
        ml: 2,
        display: 'flex',
        alignItems: 'center',
        minWidth: 'auto', // Ensures the button wraps tightly around the content
        bgcolor: 'transparent' // Makes sure there's no background color affecting the layout
      }}
      onClick={e => handleClick(e)}
    >
      <img style={{ height: '34px', width: 'auto' }} src={QrButtonIconPath} alt="QR Code Scanner" />
    </Button>
  )
}

export default ScanQrButton
