const feetMeter = 3.281
const meterMile = 1609.344

export default class ConvertDistanceUnits {
  //
  // if undefined or nil, return empty string
  //      ""
  // first using feet:
  //
  //   if feet < 20 
  //     15 ft
  //      3 ft
  //
  //   if feet >= 20 (round to nearest 10)
  //     20 ft
  //     30 ft
  //    180 ft
  //
  //   if feet > 1000 use miles
  //
  // using miles:
  //   if miles < 5
  //       .25 mi
  //        .5 mi
  //      1.35 mi
  //         4 mi
  //   TODO: need to strip extraneous /\.?0+$/
  //
  //   if miles >= 5
  //       13 mi
  //       15 mi
  //        9 mi
  //      157 mi

  toFeet = (meters) => {
    return meters * feetMeter
  }

  toMiles = (meters) => {
    return meters / meterMile
  }

  toBest = (meters) => {
    if (!meters) {
      return "" // null meters .. blank output
    }

    const miles = this.toMiles(meters)
    const feet = this.toFeet(meters)

    if (feet < 1000.0) {
      if (feet < 20.0) {
        return feet.toFixed(0) + " ft"
      } else {
        return this.roundUp(10, feet).toFixed(0) + " ft"
      }
    } else {
      if (miles >= 5.0) {
        return miles.toFixed(0) + " mi"
      } else {
        return this.roundUp(0.05, miles).toFixed(2).replace(/\.?0*$/, "") + " mi"
      }
    }
  }

  roundUp = (round, num) => {
    return Math.ceil(num/round)*round
  }
}
