import * as logger from 'loglevel'
import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import useLoadGlobalPlayableApps from '../Hooks/useLoadGlobalPlayableApps'

import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  Typography
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import EventsContainer from '../Utils/EventsContainer'
import UserAuthContainer from '../Utils/UserAuthContainer'
import UIStateContainer from '../Utils/UIStateContainer'

import anearApi from '../Api/AnearApi'

const CreateEventPage = () => {
  useLoadGlobalPlayableApps()

  const navigate = useNavigate()
  const uiStateContainer = UIStateContainer.useContainer()
  const eventsContainer = EventsContainer.useContainer()
  const userAuthContainer = UserAuthContainer.useContainer()

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const participationRadius = useState(null)[0]
  const [disabledSubmitButton, setDisableSubmitButton] = useState(false)
  const [error, setError] = useState(null)
  const { zoneId } = useParams()

  const playableApp = eventsContainer.findPlayableAppByZoneId(zoneId)
  const user = userAuthContainer.anearUser()

  useEffect(() => {
    if (playableApp && user) {
      const newName = generateDefaultName(playableApp.app, user)
      const newDescription = generateDefaultDescription(playableApp.app)
      if (name !== newName) setName(newName)
      if (description !== newDescription) setDescription(newDescription)
    }
  }, [playableApp, user, description, name])

  const generateDefaultName = (app, user) => {
    const short_name = app.attributes['short-name']
    return `${user.attributes.name}'s ${short_name}`
  }

  const generateDefaultDescription = (app) => {
    return app.attributes['long-name']
  }

  const createEventFailure = createEventError => {
    // flash a message in the outline red
    logger.error(createEventError)
    setDisableSubmitButton(false)
    setError(createEventError)
  }

  const handleEventParticipation = async (newEvent) => {
    //
    // the event creator will automatically become a participant
    // in the event, and have a private display channel,
    // but if the event is hosted, he will not be subscribed to the
    // participants channel
    //
    const anearParticipant = await anearApi.becomeEventParticipant(newEvent)
    await eventsContainer.addNewParticipant(anearParticipant)

    navigate("/e/" + newEvent.slug, { replace: true })
  }

  const createEventSuccess = async newEvent => {
    eventsContainer.addNewEvent(newEvent)

    await withProgressIndicator(handleEventParticipation, newEvent)
  }

  const withProgressIndicator = async (asyncFunction, ...args) => {
    try {
      uiStateContainer.startProgress()
      return await asyncFunction(...args)
    } catch (error) {
      createEventFailure(error)
      throw error // Re-throw the error so it can be handled by the caller
    } finally {
      uiStateContainer.progressComplete()
    }
  }
  
  const handleSubmit = async (e, zoneId) => {
    e.preventDefault()
    setDisableSubmitButton(true)

    try {
      if (playableApp.eventRequiresGeoLocation()) {
        await withProgressIndicator(userAuthContainer.setPosition)
      }

      const newEvent = await withProgressIndicator(anearApi.createEvent, zoneId, {name, description, participationRadius})
      await withProgressIndicator(createEventSuccess, newEvent)
    } catch (error) {
      createEventFailure(error)
    }
  }

  if (!playableApp) return ""

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        ml: 2,
        mr: 2
      }}
    >
      <CssBaseline />
      <Paper
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 2,
          px: 3,
          pb: 3
        }}
      >
        <Box sx={{mb: 1}}>
             <img style={{
               height: "90px",
               float: "left",
               marginRight: "16px",
             }}
             src={playableApp.app.attributes['icon-url']}
             alt={playableApp.app.attributes['short-name']}
          />
          <Typography variant="h6">
            {`Create your ${playableApp.app.attributes['short-name']} Event`}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          {playableApp.app.attributes['description']}
        </Typography>
        <Box
          component="form"
          sx={{
            width: '100%', // Fix IE 11 issue.
            mt: 1
          }}
        >
          <FormControl margin="normal" required fullWidth error={error !== null}>
            <InputLabel htmlFor="name">Event Name</InputLabel>
            <Input id="name"
              name="name"
              value={name || ""}
              autoComplete="name" required autoFocus
              onChange={e => {setName(e.target.value); setError(null)}} />
            {error && error.hasSource && error.source === 'name' && (
              <FormHelperText htmlFor="form-selector" error={error !== null}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl margin="normal" required fullWidth error={error !== null}>
            <InputLabel htmlFor="description">Event Description</InputLabel>
            <Input id="description"
              name="description"
              value={description || ""}
              autoComplete="description" required
              onChange={e => {setDescription(e.target.value); setError(null)}} />
            {error && error.hasSource && error.source === 'description' && (
              <FormHelperText htmlFor="form-selector" error={error !== null}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
          <Box>
            <Button
              sx={{
                mt: 3
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={disabledSubmitButton}
              onClick={e => handleSubmit(e, zoneId)}
            >
              Create
            </Button>
            <Link sx={{textDecoration: 'none'}} to="/apps">
              <Button
                sx={{mt: 2}}
                size="medium"
                color="primary"
              >
                <ArrowBackIcon
                   sx={{
                      mr: 1,
                      height: 20,
                      width: 20
                    }}
                />
                Back to Apps
              </Button>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default CreateEventPage
