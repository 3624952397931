import JsonApiResource from './JsonApiResource'

const HostUserType = "host"

const ParticipantsDisplayMessageType = 'PARTICIPANTS_DISPLAY'
const PrivateDisplayMessageType  = 'PRIVATE_DISPLAY'
const CssUrlMessageType = 'CSS_URL'

export default class AnearParticipant extends JsonApiResource {
  constructor(json_data, json_included, userMessaging) {
    super(json_data, json_included)
    this.userId = this.relationships['user'].data.id
    this.eventId = this.relationships['event'].data.id
    this._userMessaging = null
    this._channels =  {
      actionsChannel: null,
      privateChannel: null,
      participantsChannel: null
    }
  }

  get userType() {
    return this.attributes["user-type"]
  }

  isHost() {
    return this.userType === HostUserType
  }

  getParticipantChannels = () => {
    if (this._channels.actionsChannel) return

    this._channels.actionsChannel = this._userMessaging.getChannel(this.attributes['actions-channel-name'])
    this._channels.privateChannel = this._userMessaging.getChannel(this.attributes['private-channel-name'])
    this._channels.participantsChannel = this._userMessaging.getChannel(this.attributes['participants-channel-name'])
  }

  initMessaging = (userMessaging) => {
    this._userMessaging = userMessaging
    this.getParticipantChannels()
  }

  setActionPresence = async (requiresGeoLocation=false) => {
    await this._userMessaging.setChannelPresence(
      this._channels.actionsChannel,
      this.id,
      requiresGeoLocation
    )
  }

  updateActionPresence = async (userIdentity, requiresGeoLocation=false) => {
    await this._userMessaging.updateChannelPresence(
      this._channels.actionsChannel,
      this.id,
      requiresGeoLocation
    )
  }

  attachedToActionChannel = () => {
    const ac = this._channels.actionsChannel
    return ac && ac.state === 'attached'
  }
  
  closeMessaging = async () => {
    await this.detachAll()
  }

  detachAll = async () => {
    await this._userMessaging.detachChannel(this._channels.actionsChannel)
    await this._userMessaging.detachChannel(this._channels.privateChannel)
    await this._userMessaging.detachChannel(this._channels.participantsChannel)
    this._channels.actionsChannel = null
    this._channels.privateChannel = null
    this._channels.participantsChannel = null
  }

  subscribeEventParticipantsMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.participantsChannel, ParticipantsDisplayMessageType, callback, false)
  }

  subscribeEventPrivateMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.privateChannel, PrivateDisplayMessageType, callback, false)
  }

  subscribeEventCssMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.privateChannel, CssUrlMessageType, callback, true)
  }

  unSubscribeEventParticipantsMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.participantsChannel, ParticipantsDisplayMessageType)
  }

  unSubscribeEventPrivateMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.privateChannel, PrivateDisplayMessageType)
  }

  unSubscribeEventCssMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.privateChannel, CssUrlMessageType)
  }

  publishAction = (actionJson) => {
    const message = {
      participantId: this.id,
      payload: actionJson
    }
    this._userMessaging.publishAction(this._channels.actionsChannel, message)
  }

  publishExitEvent = () => {
    const message = {
      participantId: this.id
    }
    this._userMessaging.publishExitEvent(this._channels.actionsChannel, message)
  }
}
