import TimeAgo from 'javascript-time-ago'
 
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en'
 
// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en)
 
// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-US')
 
export default class TimeAgoTranslator {
  translate = (dateString) => {
    const date = Date.parse(dateString)
    return timeAgo.format(date)
  }
}
