const EndStates = ["closing", "closed", "review", "reward", "archive", "canceled"]
const OpeningStates = ["announce", "created"]
const NeedsRefreshStates = ["announce", "live", "closed"]
const LiveState = "live"
const PlayAgainInvitable = "play_again_invitable"
const Participant = "participant"
const Particible = "particible"
const Creator = "creator"

export default class AnearEventState {
  constructor(eventState) {
    this._eventState = eventState
  }

  state = () => {
    return this._eventState.state
  }

  context = () =>  {
    return this._eventState.context
  }

  isCancelable = () => {
    // Can abort participation before game is live, or if creator, can
    // cancel the entire event before it starts
    return !this.isOver() && (this.isParticipant() || this.isCreator())
  }

  isOpening = () => {
    return OpeningStates.includes(this.state())
  }

  isLive = () => {
    return this.state() === LiveState
  }

  isInProgress = () => {
    return !this.isOver()
  }

  isOver = () => {
    return EndStates.includes(this.state())
  }

  needsEventRefresh = (newState) => {
    return this.state() !== newState && NeedsRefreshStates.includes(newState)
  }

  isPlayAgainInvitable = () => {
    return this.context().includes(PlayAgainInvitable)
  }

  isParticipant = () => {
    // current user is currently a participant in the event
    return this.context().includes(Participant)
  }

  isCreator = () => {
    // current user created the event
    return this.context().includes(Creator)
  }

  isParticible = () => {
    /// current user is able to participate
    return this.context().includes(Particible)
  }
}
