import JsonApiResource from './JsonApiResource'

export default class AnearGuestAvatar extends JsonApiResource {
  get guestAvatarKey() {
    return this.attributes['name']
  }

  get guestAvatarUrl() {
    return this.attributes['image-url']
  }
}
