//
//  A N E A R   B R O W S E R
//
import React from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import UserAuthContainer from './components/Utils/UserAuthContainer'
import EventsContainer from './components/Utils/EventsContainer'
import UIStateContainer from './components/Utils/UIStateContainer'

import Header from './components/UI/Header'
import BurgerMenu from './components/UI/BurgerMenu'
import DefaultTheme from './DefaultTheme'
import AnearRoutes from './AnearRoutes'
import { BrowserRouter as Router } from 'react-router-dom'

const App = () => {
  return (
    <UserAuthContainer.Provider>
    <EventsContainer.Provider>
    <UIStateContainer.Provider>
      <ThemeProvider theme={DefaultTheme}>
        <CssBaseline />
        <Router>
          <div>
            <Header />
            <BurgerMenu />
            <AnearRoutes />
          </div>
        </Router>
      </ThemeProvider>
    </UIStateContainer.Provider>
    </EventsContainer.Provider>
    </UserAuthContainer.Provider>
  )
}
export default App
