import * as log from 'loglevel'
import React, { useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import {
  Avatar,
  Button,
  CssBaseline,
  Paper,
  Typography,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
} from '@mui/material'
import LockIcon from '@mui/icons-material/LockOutlined'
import anearApi from '../Api/AnearApi'
import { useTheme } from '@mui/material/styles';

import UserAuthContainer from '../Utils/UserAuthContainer'
import UIStateContainer from '../Utils/UIStateContainer'

const LoginForm = ({ error, setError, setUserArg, setPassword, handleSubmit, disabledSubmitButton }) => {
  return (
    <Box
      component="form"
      sx={{
        width: '100%', // Fix IE 11 issue.
        mt: 1, // marginTop shorthand
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl
        margin="dense"
        required
        fullWidth
        error={error !== null}
        variant="outlined"  // Use outlined variant for better visibility
      >
        <InputLabel htmlFor="user">User Name/Email</InputLabel>
        <Input
          id="user"
          name="user"
          required
          autoFocus
          onChange={e => { setUserArg(e.target.value); setError(null) }}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>

      <FormControl
        margin="dense"
        required
        fullWidth
        error={error !== null}
        variant="outlined"
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          name="password"
          type="password"
          id="password"
          required
          onChange={e => { setPassword(e.target.value); setError(null) }}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>

      <Button
        sx={{
          mt: 2, // marginTop shorthand
        }}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={disabledSubmitButton}
        onClick={handleSubmit}
      >
        Login
      </Button>
    </Box>
  )
}

const LoginPage = () => {
  const [ userArg, setUserArg ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ disabledSubmitButton, setDisabledSubmitButton ] = useState(false)
  const [ redirectToReferrer, setRedirectToReferrer ] = useState(false)
  const [ error, setError ] = useState(null)

  const userAuthContainer = UserAuthContainer.useContainer()
  const uiStateContainer = UIStateContainer.useContainer()

  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  const clearLocalState = () => {
    setUserArg('')
    setPassword('')
    setDisabledSubmitButton(false)
    setRedirectToReferrer(false)
    setError(null)
  }

  const handleSubmit = async (e) => {

    e.preventDefault()

    try {
      setDisabledSubmitButton(true)
      uiStateContainer.startProgress()
      const user = await anearApi.login(userArg, password)
      loginSuccess(user)
    } catch(error) {
      loginFailure(error)
    }
  }

  const loginSuccess = user => {
    clearLocalState()
    setRedirectToReferrer(true)
    uiStateContainer.progressComplete()
    userAuthContainer.setUser(user)
  }

  const loginFailure = error => {
    // flash a message in the login box and outline red
    uiStateContainer.progressComplete()
    setDisabledSubmitButton(false)
    setError(error)
  }

  const guestButtonHandler = async e => {
    e.preventDefault()
    try {
      setDisabledSubmitButton(true)
      uiStateContainer.startProgress()
      const user = await anearApi.getGuestAccount()
      uiStateContainer.progressComplete()
      userAuthContainer.setUser(user)
      clearLocalState()
      navigate("/apps", { replace: true })
    } catch(errResp) {
      uiStateContainer.progressComplete()
      log.error(errResp)
    }
  }

  const signupButtonHander = () => {
    clearLocalState()
    navigate("/signup", { replace: true })
  }

  let { from } = location.state || { from: { pathname: "/apps" } }

  if (redirectToReferrer || userAuthContainer.isRegisteredAuthed()) return <Navigate to={from} replace />

  const shouldShowBeOurGuestButton = !userAuthContainer.isAuthed()
  const shouldShowSignupButton = true

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block',
        ml: 3,
        mr: 3,
        [theme.breakpoints.up('sm')]: { // Adjust breakpoint value as needed
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
    >
      <CssBaseline />
      <Paper
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItem: 'center',
          pt: 2,
          px: 3,
          pb: 3
        }}
      >
        <Avatar
          sx={{
            m: 1, // Margin shorthand
            backgroundColor: 'secondary.main',
          }}
        >
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anear Login
        </Typography>

        <LoginForm
          error={error}
          setError={setError}
          setUserArg={setUserArg}
          setPassword={setPassword}
          handleSubmit={handleSubmit}
          disabledSubmitButton={disabledSubmitButton}
        />

        {shouldShowBeOurGuestButton && (
          <Button
            sx={{ mt: 1 }} // marginTop shorthand
            size="small"
            color="secondary"
            onClick={guestButtonHandler}
          >
            Continue As Guest
          </Button>
        )}
        {shouldShowSignupButton && (
          <Button
            sx={{ mt: 1 }} // marginTop shorthand
            size="small"
            color="secondary"
            onClick={signupButtonHander}
          >
            Signup
          </Button>
        )}
      </Paper>
    </Box>
  )
}

export default LoginPage
