import React from 'react'
import FormHelperText from '@mui/material/FormHelperText'

const FormErrorHelper = ({error, attrName}) => (
  <div>
    {error && error.hasSource && error.source === attrName && (
      <FormHelperText htmlFor="form-selector" error={error !== null}>
        {error.message}
      </FormHelperText>
    )}
  </div>
)

export default FormErrorHelper
