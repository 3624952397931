import * as logger from 'loglevel'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

const ValidQRPattern = /^https?:\/\/anear\.me\/e\/[0-9a-zA-Z]{9}$/

const QRScanWindow = ({ onBadScan, onEmptyScan }) => {
  const navigate = useNavigate()
  const validQRCodeURL = url => url.match(ValidQRPattern) !== null
  const parseRouterUrl = data => data.match(/\/e\/.*/)[0]
 
  const handleScan = data => {
    if (data) {
      logger.info(`scanned: ${data}`)

      if (validQRCodeURL(data)) {
        const anearPath = parseRouterUrl(data)

        navigate(anearPath, { replace: true })
      } else {
        onBadScan()
      }
    } else {
      onEmptyScan()
    }
  }

  const handleError = err => logger.error(err)

  const scannerStyle = {
    marginTop: "10px",
    width: '100%'
  }

  return (
    <Paper square elevation={3}>
      <QrReader
        style={scannerStyle}
        delay={300}
        onError={handleError}
        onScan={handleScan}
      />
    </Paper>
  )
}

const ScanPage = _ => {
  const navigate = useNavigate()
  const [ error, setError ] = useState(null)

  const onClose = () => {
    navigate("/apps", { replace: true })
  }

  const onBadScan = () => {
    setError("Invalid QR Code")
  }

  const onEmptyScan = () => {
    setError(null)
  }

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Paper square elevation={3}>
            <Typography
              variant="h6"
              align="center"
              color="secondary"
              sx={{
                mt: 1,
                ml: 1,
                mr: 1
              }}
            >
              {"Scan QR Code to Join an Event"}
            </Typography>
            <Typography
              sx={{
                ml: 1,
                mr: 1,
                mt: 0.5,
                fontWeight: 'bold',
                height: "2em"
              }}
              variant="subtitle1"
              align="center"
              color="error"
            >
              { error }
            </Typography>
          </Paper>

          <QRScanWindow onBadScan={onBadScan} onEmptyScan={onEmptyScan}/>

        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  )
}

export default ScanPage
