import JsonApiResource from './JsonApiResource'

export default class AnearUser extends JsonApiResource {

  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.profile = this.findIncluded(this.relationships["profile"])
  }

  name = () => {
    return this.attributes.name
  }

  fullName = () => {
    return `${this.profile.attributes['first-name']||""} ${this.profile.attributes['last-name']||""}`
  }

  initial = () => {
    return this.attributes.name.substr(0,1).toUpperCase()
  }

  avatarUrl = () => {
    return this.profile.attributes['avatar-url']
  }

  guestAvatarKey = () => {
    return this.profile.attributes['guest-avatar-key']
  }

  distance = () => {
    return this.attributes.distance || null // 
  }

  memberSince = () => {
    return this.attributes['created-at']
  }
}
