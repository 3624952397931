import React from 'react'
import UserAvatar from './UserAvatar'
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

class EventAvatar extends React.Component {

  render() {
    const { anearEvent } = this.props
    const appAttrs = anearEvent.zone.app.attributes

    return (
      <Box sx={{height: '32px'}}>
        <Avatar
          src={appAttrs['icon-url']}
          aria-label="Event App Icon with Creator Avatar"
          sx={{
            height: 30,
            width: 30,
            zIndex: 1
          }}
        />
        <Box
          sx={{
            position: "relative",
            top: "-25px",
            left: "18px",
            zIndex: 2
          }}
        >
          <UserAvatar anearUser={anearEvent.user} size={"tinyAvatar"}/>
        </Box>
      </Box>
    )
  }
}

export default EventAvatar


