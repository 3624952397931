import UserAuthContainer from './components/Utils/UserAuthContainer'
import useLoadUser from './components/Hooks/useLoadUser'

const AuthenticatedRoute = ({ element }) => {
  useLoadUser()

  const userAuth = UserAuthContainer.useContainer()

  return userAuth.isAuthed() ? element : ""
}

export default AuthenticatedRoute
