import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  tinyAvatar: {
    backgroundColor: theme.palette.primary,
    color: '#fff',
    height: 32,
    width: 32,
  },
  smallAvatar: {
    backgroundColor: theme.palette.primary,
    color: '#fff',
    height: 40,
    width: 40,
  },
  mediumAvatar: {
    backgroundColor: theme.palette.primary,
    color: '#fff',
    height: 60,
    width: 60,
  },
  bigAvatar: {
    backgroundColor: theme.palette.primary,
    color: '#fff',
    height: 80,
    width: 80,
  },
  hugeAvatar: {
    backgroundColor: theme.palette.primary,
    color: '#fff',
    height: 128,
    width: 128,
  },
})

class UserAvatar extends React.Component {

  getNameSize = (size) => {
    switch(size) {
      case 'tinyAvatar':
      case 'smallAvatar':
      case 'mediumAvatar':
        return 'subtitle2'
      default:
        return 'h6'
    }
  }

  render() {

    const { classes, anearUser, size = 'smallAvatar', withName = false } = this.props
    const imgProps = this.props.imgProps || {}
    const avatarClass = classes[size]
    const withNameSize = withName ? this.getNameSize(size) : null

    return (
      <div>
      {
        anearUser.avatarUrl() ? (
          <Avatar className={avatarClass} imgProps={imgProps} alt={anearUser.name()} src={anearUser.avatarUrl()} />
        ) : (
          <Avatar className={avatarClass}>{anearUser.initial()}</Avatar>
        )
      }
      {
        withName && (
          <Typography className={classes.userName} color='secondary' variant={withNameSize} align='center'>
            {anearUser.name()}
          </Typography>
        )
      }
      </div>
    )
  }
}

UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  anearUser: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserAvatar);
