import * as logger from 'loglevel'
import React, { memo } from 'react'
import { Box } from '@mui/material'
import DOMPurify from 'dompurify'

const AnearActionClick  = 'anear-action-click'
const AnearActionForm   = 'anear-action-form'
const AnearBrowserClick = 'anear-browser-click'
const AnearQrCode       = 'anear-qr-code'
const AnearUserAvatar   = 'anear-user-avatar'

const NotClickable = 'null'
const DefaultFrameContent = "<p>&nbsp;&nbsp;&nbsp;loading app...</p>"

const ClickUrl = `${process.env.REACT_APP_ANEAR_APP_ASSETS_URL}/click.wav`
const AudibleClick = new Audio(ClickUrl)

const AppDisplay = memo(({ anearEvent, frameContent, frameContentRef, lastMessageId }) => {
  const safeHTML = DOMPurify.sanitize(frameContent)

  return (

    <Box sx={{ width: 'auto', height: '560px', ml: 1 }}>
      <div
        id={anearEvent.id}
        ref={frameContentRef}
        dangerouslySetInnerHTML={{ __html: safeHTML }}
      />
    </Box>
  )
}, (prevProps, nextProps) => {
  // This function determines if AppDisplay should re-render.
  // Returning true if props are equal (no re-render), or false if props are different (trigger re-render).
  return prevProps.lastMessageId === nextProps.lastMessageId
})


class EventFrame extends React.Component {
  //
  // The EventFrame interacts with Ably Channels API to receive display
  // content from App servers for rendering... and accepts user inputs for relay back to
  // app servers
  //

  constructor(props) {
    super(props)
    this.frameContentRef = React.createRef()
    this.lastMessageId = null

    this.state = {
      frameContent: DefaultFrameContent,
      timeout: {
        timeoutMsecs: 0,
        timeRemaining: 0
      },
      actionSubscribed: false
    }
  }

  componentDidMount = async () => {
    const { anearEvent, eventsContainer, userAuthContainer } = this.props

    logger.info(`EventFrame componentDidMount for event ${anearEvent.id}`)

    this.setCurrentUserAvatar(userAuthContainer)

    const participant = eventsContainer.getParticipantForEvent(anearEvent.id)

    if (participant) {
      this.setState({actionSubscribed: true})
      await this.setParticipantDisplayHandler(
        anearEvent,
        participant,
        this.displayParticipantMessageHandler
      )
      await participant.subscribeEventCssMessages(this.cssHandler)
    } else {
      await this.setSpectatorDisplayHandler(anearEvent, this.displaySpectatorMessageHandler)
    }
  }

  componentWillUnmount = () => {
    const { anearEvent } = this.props

    logger.info(`componentWillUnmount() called on event ${anearEvent.id}`)

    this.clearActionHandlers()

    this.removeAppCss(anearEvent)
  }

  setParticipantDisplayHandler = async (anearEvent, participant, participantDisplayHandler) => {
    await participant.setActionPresence(anearEvent.requiresGeoLocationToParticipate())

    if (!participant.isHost()) {
      // Do not subscribe to participant messages if we are the event host
      // That way, when the App publishes to this channel, the Event Host
      // will not receive these, as she is not a normal participant
      participant.subscribeEventParticipantsMessages(participantDisplayHandler)
    }

    participant.subscribeEventPrivateMessages(participantDisplayHandler)

    // if the participant began as a spectator, this will stop subscribing to
    // these messages
    await anearEvent.closeSpectatorsChannel()
  }

  setSpectatorDisplayHandler = async (anearEvent, spectatorDisplayHandler) => {
    if (anearEvent.spectatorsAllowed()) {
      await anearEvent.subscribeSpectatorMessages(this.anearUser, spectatorDisplayHandler)
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { anearEvent, eventsContainer, userAuthContainer } = prevProps

    logger.info(`EventFrame componentDidUpdate for event ${anearEvent.id}`)

    const participant = eventsContainer.getParticipantForEvent(anearEvent.id)

    this.setCurrentUserAvatar(userAuthContainer)

    if (participant) {
      if (!this.state.actionSubscribed) {
        this.setState({actionSubscribed: true})
        await this.setParticipantDisplayHandler(
          anearEvent,
          participant,
          this.displayParticipantMessageHandler
        )
      }

      this.setQrCodeImgSrc(anearEvent)
      this.setActionClicks(this.actionClickMessage)
      this.setBrowserClicks(this.participantBrowserClickCommand)

    } else if (anearEvent.spectatorsAllowed()) {
      this.setBrowserClicks(this.spectatorBrowserClickCommand)
    }
  }

  cssHandler = message => {
    const cssUrl = message.data
    if (cssUrl) this.ensureAppCss(cssUrl)
  }

  displayMessageHandler = message => {
    const { content, timeout } = message.data
    this.lastMessageId = message.id

    this.setState({ frameContent: content, timeout })
  }

  displaySpectatorMessageHandler = message => {
    logger.info(`spectator message rcvd: ${message.name}, timeout remaining: ${message.data.timeout.timeRemaining}`)
    this.displayMessageHandler(message)
  }

  displayParticipantMessageHandler = message => {
    logger.info(`participant message rcvd: ${message.name}, timeout remaining: ${message.data.timeout.timeRemaining}`)
    this.displayMessageHandler(message)
  }

  actionClickMessage = (e) => {
    const { anearEvent, eventsContainer } = this.props
    const participant = eventsContainer.getParticipantForEvent(anearEvent.id)

    this.clearActionHandlers()

    const message = e.target.getAttribute(AnearActionClick)
    participant.publishAction(message)
    return false
  }

  actionFormSubmitMessage = (e) => {
    const { anearEvent, eventsContainer } = this.props

    this.clearActionHandlers()

    const form = e.target.closest(`[${AnearActionForm}]`)
    const message = this.getActionFormMessage(form)
    const participant = eventsContainer.getParticipantForEvent(anearEvent.id)

    participant.publishAction(message)

    return false
  }

  participantBrowserClickCommand = async e => {
    const command = e.target.getAttribute(AnearBrowserClick)

    logger.info(`got ${command} from anear-browser-click`)

    switch(command) {
      case 'back':
      case 'replay':
        await this.props.clickHandlers(e, command)
        break;
      default:
        throw new Error(`unhandled or invalid command ${command}`)
    }
    return false
  }

  spectatorBrowserClickCommand = async e => {
    const command = e.target.getAttribute(AnearBrowserClick)

    logger.info(`got ${command} from anear-browser-click`)

    switch(command) {
      case 'back':
      case 'join':
        await this.props.clickHandlers(e, command)
        break;
      default:
        throw new Error(`unhandled or invalid command ${command}`)
    }
    return false
  }
  clearActionClicks = () => {
    this.setActionClicks(undefined)
  }

  clearActionFormSubmitClick = () => {
    this.setActionFormSubmitClick(undefined)
  }

  clearBrowserClicks = () => {
    this.setBrowserClicks(undefined)
  }

  clearActionHandlers = () => {
    this.clearActionClicks()
    this.clearActionFormSubmitClick()
    this.clearBrowserClicks()
  }

  audibleClick = async (e, handler) => {
    handler(e)
    await AudibleClick.play()
  }

  setActionClicks = handler => {
    const elems = this.frameContentRef.current.querySelectorAll(`[${AnearActionClick}]`)
    const h = handler ? e => this.audibleClick(e, handler) : undefined

    elems.forEach(elem => {
      if (elem.getAttribute(AnearActionClick) !== NotClickable) {
        elem.onclick = h
      }
    })
  }

  setActionFormSubmitClick = handler => {
    const form = this.frameContentRef.current.querySelector(`[${AnearActionForm}]`)
    if (!form) return
    const submit = this.anearFormSubmit(form)
    submit.onclick = handler
  }

  setBrowserClicks = handler => {
    const elems = this.frameContentRef.current.querySelectorAll(`[${AnearBrowserClick}]`)
    elems.forEach(elem => {
      if (elem.getAttribute(AnearBrowserClick) !== NotClickable) {
        elem.onclick = handler
      }
    })
  }

  getQrCodeElems = () => {
    return this.frameContentRef.current.querySelectorAll(`[${AnearQrCode}]`)
  }

  getUserAvatarElems = () => {
    return this.frameContentRef.current.querySelectorAll(`[${AnearUserAvatar}]`)
  }

  setCurrentUserAvatar = userAuthContainer => {
    const elems = this.getUserAvatarElems()

    if (!elems) return

    elems.forEach(img => {
      img.src = userAuthContainer.avatarUrl()
      img.alt = `Avatar for ${userAuthContainer.name()}`
    })
  }

  setQrCodeImgSrc = anearEvent => {
    const elems = this.getQrCodeElems()

    if (!elems) return

    elems.forEach(img => {
      img.src = anearEvent.attributes['qr-image-url']
      img.alt = `QR Code for ${anearEvent.name}`
    })
  }

  getActionFormMessage = (form) => {
    const inputs = this.anearFormInputs(form)
    const formValues = {}

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].tagName === 'SELECT') {
        formValues[inputs[i].id] = [...inputs[i].options]
          .filter(option => option.selected)
          .map(option => option.value)
      } else  {
        formValues[inputs[i].id] = inputs[i].value
      }
    }

    const eventName = form.getAttribute(AnearActionForm)

    return JSON.stringify(
      {
        [eventName]: formValues
      }
    )
  }

  anearFormInputs = (form) => {
    return form.querySelectorAll('input:not([type=submit]), select, textarea')
  }

  anearFormSubmit = (form) => {
    return form.querySelector('input[type=submit]')
  }

  eventAppStyleId = anearEvent => {
    return `style-${anearEvent.zone.app.id}`
  }

  getAppStyleElement = anearEvent => {
    const appStyleId = this.eventAppStyleId(anearEvent)
    return {
      appStyle: document.getElementById(appStyleId),
      appStyleId
    }
  }

  removeAppCss = anearEvent => {
    const { appStyle, appStyleId } = this.getAppStyleElement(anearEvent)

    if (appStyle) {
      logger.info(`removing existing style with id=${appStyleId}`)
      appStyle.parentNode.removeChild(appStyle)
    }
  }

  ensureAppCss = cssUrl => {
    const { anearEvent } = this.props

    const { appStyle, appStyleId } = this.getAppStyleElement(anearEvent)

    if (!appStyle) {
      const newAppStyle = document.createElement("link")

      newAppStyle.setAttribute("id", appStyleId)
      newAppStyle.setAttribute("rel", "stylesheet")
      newAppStyle.setAttribute("href", cssUrl)

      document.head.appendChild(newAppStyle)

      logger.info(`set CSS styles for styleId ${appStyleId}`)
    }
  }

  render() {
    const { anearEvent } = this.props

    logger.info(`render EventFrame for ${anearEvent.id}`)

    const { frameContent } = this.state

    return(
      <>
        <AppDisplay
          frameContentRef={this.frameContentRef}
          anearEvent={anearEvent}
          frameContent={frameContent}
          lastMessageId={this.lastMessageId}
        />
      </>
    )
  }
}

export default EventFrame
