import React from 'react'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material'

const AppCard = ({ playableApp }) => {
  const attrs = playableApp.app.attributes
  const imageUrl = attrs['icon-url']

  return (
    <Card sx={{ display: 'flex', maxWidth: 360 }} elevation={3}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="h6" variant="h6">
            {attrs['short-name']}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {attrs['long-name']}
          </Typography>
        </CardContent>
      </Box>
      {imageUrl ? (
        <CardMedia
          sx={{ width: 160, height: 160, objectFit: 'cover' }}
          image={imageUrl}
          title={attrs['short-name']}
        />
      ) : (
        <Box sx={{ width: 160, height: 160, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: 'grey.300' }}>
          <Typography variant="caption" color="textSecondary">
            No Image Available
          </Typography>
        </Box>
      )}
    </Card>
  )
}

export default AppCard
