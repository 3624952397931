import { useState } from 'react'
import { createContainer } from 'unstated-next'
import TokenStorage from '../Api/TokenStorage'
import UserMessaging from '../Utils/UserMessaging'
import anearApi from '../Api/AnearApi'
import * as logger from 'loglevel'

const useUserAuth = () => {
  const initialState = {
    user: null,
    avatarIndex: null,
    position: {}
  }
  
  const tokenStorage = new TokenStorage()
  const userMessaging = new UserMessaging()
  const [state, setState] = useState(initialState)

  const gset = newState => {
    setState((currentState) => ({ ...currentState, ...newState }))
  }

  const setPosition = async () => {
    let geoPosition = {}
    let errMsg = ''

    try {
      geoPosition = await anearApi.getPosition()
    } catch (err) {
      errMsg = `[${err}]`
      geoPosition = {}
    }
    logger.info(`geoPosition: ${errMsg}`, geoPosition)
    gset({ position: geoPosition })
  }

  const loadUser = async (force = false) => {
    if (!force && isAuthed()) return

    let user = await anearApi.getAccount()

    userMessaging.initRealtime()
    gset({ user })
    logger.info(`${user.name()} account loaded, MobileSafari=${mobileSafari()}`)
  }

  const clearUser = () => {
    gset({ user: null })
  }

  const hasRegisteredToken = () => {
    return tokenStorage.hasRegisteredToken()
  }

  const isAuthed = () => {
    // user defined in state AND a token backing it in localStorage
    return state.user !== null && tokenStorage.getTokenKey() !== null
  }

  const isRegisteredAuthed = () => {
    return isAuthed() && hasRegisteredToken()
  }

  const isGuestAuthed = () => {
    return isAuthed() && tokenStorage.hasGuestToken()
  }

  const clearAuthToken = () => {
    return tokenStorage.clearAuthMeta()
  }

  const clearUserAndToken = () => {
    clearUser()
    clearAuthToken()
  }

  const hasAuthToken = () => {
    return tokenStorage.hasAuthMeta()
  }

  const anearUser = () => {
    return state.user
  }

  const profile = () => {
    return state.user.profile.attributes
  }

  const name = () => {
    return state.user && state.user.name()
  }

  const fullName = () => {
    return state.user.fullName()
  }

  const initial = () => {
    return state.user.initial()
  }

  const avatarUrl = () => {
    return state.user.avatarUrl()
  }

  const avatarIndex = () => {
    return state.avatarIndex
  }

  const currentLat = () => {
    const coords = state.position.coords || {}
    return coords.latitude // type double
  }

  const currentLong = () => {
    const coords = state.position.coords || {}
    return coords.longitude // type double
  }

  const loadAvatarIndex = async () => {
    const avatarIndex = await anearApi.getGuestAvatarIndex()
    gset({ avatarIndex })
    return avatarIndex
  }

  const setUser = user => {
    gset({ user })
    logger.info(`${user.name()} account set, MobileSafari=${mobileSafari()}`)
  }

  const mobileSafari = () => {
    const ua = window.navigator.userAgent
    const iOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream
    const webkit = !!ua.match(/WebKit/i)

    return iOS && webkit && !ua.match(/CriOS/i)
  }

  const canAccessCamera = () => {
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia
  }

  return {
    anearUser,
    avatarIndex,
    loadAvatarIndex,
    avatarUrl,
    canAccessCamera,
    clearAuthToken,
    clearUser,
    clearUserAndToken,
    currentLat,
    currentLong,
    fullName,
    hasAuthToken,
    hasRegisteredToken,
    initial,
    isAuthed,
    isGuestAuthed,
    isRegisteredAuthed,
    loadUser,
    mobileSafari,
    name,
    profile,
    setPosition,
    setUser,
    state,
  }
}

const UserAuthContainer = createContainer(useUserAuth)
export default UserAuthContainer
