const TOKEN_KEY = 'anearAuthMeta'

export default class TokenStorage {

  setAuthMeta = (authMeta) => {
    // Saves user token to localStorage
    localStorage.setItem(TOKEN_KEY, JSON.stringify(authMeta))
  }

  getAuthMeta = () => {
    // Retrieves the user token from localStorage
    return JSON.parse(this.getTokenKey())
  }

  getTokenKey= () => {
    return localStorage.getItem(TOKEN_KEY)
  }

  hasGuestToken = () => {
    return this.hasTokenOfType("guest")
  }

  hasRegisteredToken = () => {
    return this.hasTokenOfType("registered")
  }

  hasTokenOfType = acctType => {
    const meta = this.getAuthMeta()
    return meta && meta['auth-token'] && meta['acct-type'] === acctType
  }

  clearAuthMeta = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem(TOKEN_KEY)
  }

  hasAuthMeta = () => {
    return this.getAuthMeta() !== null
  }

}
