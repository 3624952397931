import * as log from 'loglevel'
import { DirectUpload } from 'activestorage'
import TokenStorage from './TokenStorage'
import ErrorResponse from './ErrorResponse'
import AuthTokenExpiredError from './AuthTokenExpiredError'
import AnearUser from '../Models/AnearUser'

const qs = require('qs')

const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: false, // might take longer on a device, but useful for Anear
  timeout: (process.env.REACT_APP_GEOCOORDS_TIMEOUT_SECS*1000), // Timeout getting coords
  maximumAge: (process.env.REACT_APP_CACHE_GEOCOORDS_SECONDS*1000) // cached coords duration
}

export default class ApiService {

  constructor() {
    this.tokenStorage = new TokenStorage()
    this.api_base_url = process.env.REACT_APP_ANEARAPP_API_URL
    this.direct_uploads_url = process.env.REACT_APP_ANEARAPP_DIRECT_UPLOADS_URL
    this.defaultHeaderObject = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.REACT_APP_ANEARAPP_API_KEY
    }
    this.default_headers = new Headers(this.defaultHeaderObject)
  }

  getPosition = (options=GEOLOCATION_OPTIONS) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })
  }

  createAnearUser = json => {
    return new AnearUser(json.data, json.included)
  }

  setGeolocationHeader = async (request, required) => {
    if (!required) return request

    try {
      const position = await this.getPosition()
      request.headers.append('Geolocation', this.formatGeolocationHeader(position))
      return request
    } catch(error) {
      let msg = error.message
      switch(error.code) {
        case error.PERMISSION_DENIED:
            msg = "Geolocation is required to proceed"
            break
        case error.POSITION_UNAVAILABLE:
            msg = "Location information is unavailable"
            break
        case error.TIMEOUT:
            log.error("The request to get user location timed out")
            return request
        case error.UNKNOWN_ERROR:
            break
        default:
            break
      }
      throw new Error(msg)
    }
  }

  formatGeolocationHeader = ({coords, timestamp})=> {
    const posLatLng = `Position=[${coords.longitude},${coords.latitude}`
    const posAltitude = coords.altitude ? `,${coords.altitude}]` : ']'
    const accuracyTimestamp = `; Accuracy=${coords.accuracy}; Timestamp=${timestamp};`

    let geoOptional = ''
    if (coords.altitude && coords.altitudeAccuracy) geoOptional += ` AltitudeAccuracy=${coords.altitudeAccuracy};` 
    if (coords.speed) geoOptional += ` Speed=${coords.speed};`
    if (coords.heading) geoOptional += ` Heading=${coords.heading};`

    return posLatLng + posAltitude + accuracyTimestamp + geoOptional
  }

  idParamUrlString = (resource, params) => {
    let idParam = ''
    if (params.id) {
      idParam = `/${params.id}`
      delete params.id
    }
    const paramString = qs.stringify(params)
    let queryParams = ''
    if (paramString) queryParams = `?${paramString}`
    return `${this.api_base_url}/${resource}${idParam}${queryParams}`
  }

  prepareGetRequest = (resource, params={}) => {
    const urlString = this.idParamUrlString(resource, params) 
    return new Request(
      urlString, {
        method: 'GET',
        headers: this.default_headers
      }
    )
  }

  get = async (resource, params, needsLocation) => {
    const request = this.prepareGetRequest(resource, params)
    return this.issueRequest(request, needsLocation)
  }

  post = async (resource, attributes, needsLocation, relationships={}) => {
    const payload = this.formatPayload(resource, attributes, relationships)
    const request = new Request(
      `${this.api_base_url}/${resource}`, {
        method: 'POST',
        headers: this.default_headers,
        body: JSON.stringify(payload)
      }
    )
    return this.issueRequest(request, needsLocation)
  }

  put = async (resource, id, attributes, needsLocation, relationships={}) => {
    const payload = this.formatPayload(resource, attributes, relationships)
    const urlString = `${this.api_base_url}/${resource}/${id}`
    const request = new Request(
      urlString, {
        method: 'PUT',
        headers: this.default_headers,
        body: JSON.stringify(payload)
      }
    )
    return this.issueRequest(request, needsLocation)
  }

  httpDelete = async (resource, id=null) => {
    const idStr = (id ? `/${id}` : '')
    const urlString = `${this.api_base_url}/${resource}${idStr}`
    const request = new Request(
      urlString, {
        method: 'DELETE',
        headers: this.default_headers
      }
    )
    return this.issueRequest(request, false)
  }

  setAuthorizationHeader = request => {
    if (this.tokenStorage.hasAuthMeta()) {
      this.updateAuthHeader(request)
    }
  }

  getAuthorizationBearer = () => {
    const authMeta = this.tokenStorage.getAuthMeta()
    return `Bearer ${authMeta['auth-token']}`
  }

  updateAuthHeader = (request) => {
    request.headers.append('Authorization', this.getAuthorizationBearer())
  }

  getGuestAccount = async () => {
    const request = this.prepareGetRequest("accounts")
    const response = await fetch(request)
    const json = await this.checkStatus(response)
    this.setAuthMetaFromResponse(json)
    return this.createAnearUser(json)
  }

  issueRequest = async (request, needsLocation) => {
    this.setAuthorizationHeader(request)
    request = await this.setGeolocationHeader(request, needsLocation)
    const response = await fetch(request)
    const json = await this.checkStatus(response)
    this.setAuthMetaFromResponse(json)
    return json
  }

  formatPayload = (resource, attributes, relationships) => {
    return {
      data: {
        type: resource,
        attributes,
        relationships: this.formatRelationships(relationships)
      }
    }
  }

  formatRelationships = (relationships) => {
    //
    // e.g. formatRelationships({event: eventId, user: userId})
    //
    const output = {}

    Object.entries(relationships).forEach(rel => {
      const resource = rel[0]
      const id = rel[1]

      output[resource] = {
        data: {
          type: resource + 's',
          id: id
        }
      }
    })
    return output
  }

  checkStatus = async response => {
    if (response.status === 204) {
      return {}
    } else {
      const json = await response.json()
      if (response.ok) {
        return json
      } else {
        const error = json.errors[0]
        if (error.status === 401) {
          // expired token
          throw new AuthTokenExpiredError(error.message)
        } else {
          throw new ErrorResponse(json)
        }
      }
    }
  }

  setAuthMetaFromResponse = json => {
    if (json.hasOwnProperty('meta')) {
      this.tokenStorage.setAuthMeta(json.meta)
    }
  }

  createUpload = (upload) => {
    return new Promise((resolve, reject) => {
      upload.create((err, blob) => {
        if (err) reject(err);
        else resolve(blob);
      })
    })
  }

  activeStorageUpload = async (userName, blob) => {

    blob.lastModifiedDate = new Date();
    blob.name = `${userName}_avatar.jpg`

    const upload = new DirectUpload(
      blob,
      this.direct_uploads_url,
      {
        directUploadWillCreateBlobWithXHR: (xhr) => {
          // This will decorate the requests with the access token header so you won't get a 401
          xhr.setRequestHeader('Authorization', this.getAuthorizationBearer())
        }
      }
    )

    log.info(`uploading ${blob.name}...`)

    return this.createUpload(upload);
  }
}
