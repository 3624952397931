class AnearEvents {
  //
  // cache static data and active realtime messaging for
  // the authd user's ongoing events and participations
  //
  constructor(userMessaging) {
    this.loaded = {}
    this.anearParticipants = {}
    this.userMessaging = userMessaging
  }

  isEventLoadedSlug = eventSlug => {
    return !!this.getEventFromSlug(eventSlug)
  }

  getEventFromSlug = eventSlug => {
    return Object.values(this.loaded).find(anearEvent => anearEvent.slug === eventSlug)
  }

  isEventLoaded = eventId => {
    return this.loaded.hasOwnProperty(eventId)
  }

  hasParticipantForEvent = eventId => {
    return this.anearParticipants.hasOwnProperty(eventId)
  }

  getEvent = eventId => {
    return this.loaded[eventId]
  }

  addEventAndInitMessaging = anearEvent => {
    anearEvent.initMessaging(this.userMessaging)
    this.loaded[anearEvent.id] = anearEvent
  }

  addParticipantAndInitMessaging = anearParticipant => {
    const eventId = anearParticipant.eventId

    anearParticipant.initMessaging(this.userMessaging)
    this.anearParticipants[eventId] = anearParticipant
  }

  removeEventAndParticipant = async eventId => {
    if (!this.isEventLoaded(eventId)) return

    await this.removeParticipant(eventId)
    await this.deleteEvent(eventId)
  }

  deleteEvent = async eventId => {
    if (!this.isEventLoaded(eventId)) return
    const anearEvent = this.getEvent(eventId)
    await anearEvent.closeMessaging()
    delete this.loaded[eventId]
  }

  getParticipantForEvent = eventId => {
    return this.anearParticipants[eventId]
  }

  removeParticipant = async eventId => {
    const anearParticipant = this.getParticipantForEvent(eventId)

    if (anearParticipant) {
      // closeMessaging() detaches from the actions channel,
      // which will publish a 'leave' message to the downstream
      // app.  Its the remote app's responsibility to close out the
      // participation with the API and possibly terminate the
      // event
      await anearParticipant.closeMessaging()
      delete this.anearParticipants[eventId]
    }
  }
}

export default AnearEvents
