import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage = _ => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/apps", { replace: true })
  })

  return (
    <>
    </>
  )
}

export default HomePage
