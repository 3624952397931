class JsonApiResource {

  constructor(json_data, json_included) {
    this.data = json_data
    this.attributes = json_data.attributes
    this.relationships = json_data.relationships
    this.included = json_included
    this.id = json_data.id
  }

  findIncluded = (resourceIdentifier) => {
    const data = resourceIdentifier.data
    if (!data) return null
    return this.included.find(inc => {
      return inc.type === data.type && inc.id === data.id
    })
  }
}

export default JsonApiResource
