import React from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'
import EventAvatar from './EventAvatar'
import ConvertDistanceUnits from '../Utils/ConvertDistanceUnits'
import TimeAgoTranslator from '../Utils/TimeAgoTranslator'

const distanceConverter = new ConvertDistanceUnits()
const timeAgoTranslator = new TimeAgoTranslator()

class EventCardHeader extends React.Component {

  genSubHeaderText = (distance, createdAt) => {
    let distanceText = null
    let timeAgoText = null

    if (distance) {
      const distWords = distanceConverter.toBest(parseFloat(distance))
      distanceText = `within ${distWords} of you`
    }
    if (createdAt) {
      const timeAgoWords = timeAgoTranslator.translate(createdAt)
      timeAgoText = `created ${timeAgoWords}`
    }

    return (
      <>
        {
          distanceText && <p>{distanceText}</p>
        }
        {
          timeAgoText && <p>{timeAgoText}</p>
        }
      </>
    )
  }

  render() {
    const { anearEvent } = this.props

    return (
      <CardHeader
        sx={{
            height: '40px',
            padding: '4px',
            '& p': {
              margin: 0,
            }
        }}
        avatar={<EventAvatar anearEvent={anearEvent}/>}
        title={anearEvent.name}
        titleTypographyProps={{ variant:'subtitle1', align:'center' }}
      />
    )
  }
}

EventCardHeader.propTypes = {
  distance: PropTypes.string,
  createdAt: PropTypes.string,
  anearEvent: PropTypes.object.isRequired
}

export default EventCardHeader
