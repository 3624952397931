import JsonApiResource from './JsonApiResource'
import AnearZone from './AnearZone'
import AnearUser from './AnearUser'

const BroadcastMessageType       = 'broadcast'
const PublicDisplayMessageType   = 'public_display'
const CssDisplayMessageType      = 'css_display'
const EventTransitionMessageType = 'event_transition'
const PlayAgainMessageType       = 'play_again'

export default class AnearEvent extends JsonApiResource {
  // represents the state of the event at time of fetch
  // from the Anear API.  Ably event transition messages can
  // change the live state kept in the EventPage state.
  //
  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.zone = new AnearZone(this.findIncluded(this.relationships['zone']), this.included)
    this.user = new AnearUser(this.findIncluded(this.relationships['user']), this.included)
    this._userMessaging = null
    this._channels =  {
      eventChannel: null,
      spectatorsChannel: null
    }
  }

  get name() {
    return this.attributes.name
  }

  get slug() {
    return this.attributes.slug
  }

  get short_url() {
    return `/e/${this.slug}`
  }

  get state() {
    return this.attributes.state
  }

  get context() {
    return this.attributes.context
  }

  get hosted() {
    // A hosted event possibly has a participant who only acts as the moderator or "host".
    // He/she typically does not participate in game play.  E.g.  Trivia contest organizer and master of ceremonies.
    // Or, a merchnat sponsoring an event at his place of business
    return this.attributes.hosted
  }

  getEventState = () => {
    return {
      state: this.state,
      context: this.context
    }
  }

  get canPlayAgain() {
    return this.zone.canPlayAgain
  }

  requiresGeoLocationToParticipate = () => {
    // if the event has a participation radius, then a participant must
    // have a geoLocation set in the browser for validation by the Anear API
    return this.attributes['participation-radius']
  }

  spectatorsAllowed = () => {
    return !this.attributes.flags.includes("no_spectators")
  }

  attachedToSpectatorsChannel = () => {
    const sc = this._channels.spectatorsChannel
    return sc && sc.state === 'attached'
  }

  initEventChannel = () => {
    if (!this._channels.eventChannel) {
      this._channels.eventChannel = this._userMessaging.getChannel(this.attributes['event-channel-name'])
    }
  }

  initMessaging = (userMessaging) => {
    this._userMessaging = userMessaging
    this.initEventChannel()
  }

  initSpectatorMessaging = () => {
    if (this.spectatorsAllowed() && !this._channels.spectatorsChannel) {
      this._channels.spectatorsChannel = this._userMessaging.getChannel(this.attributes['spectator-channel-name'])
    }
  }

  closeMessaging = async () => {
    await this.detachAll()
  }

  detachAll = async () => {
    await this._userMessaging.detachChannel(this._channels.eventChannel)
    this._channels.eventChannel = null
  }

  subscribeEventBroadcastMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, BroadcastMessageType, callback)
  }

  subscribeEventTransitionMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, EventTransitionMessageType, callback)
  }

  subscribeEventPlayAgainMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, PlayAgainMessageType, callback)
  }

  subscribeSpectatorMessages = async (anearUser, callback) => {
    this._userMessaging.subscribeEventMessages(this._channels.spectatorsChannel, PublicDisplayMessageType, callback, false)
    await this._userMessaging.setChannelPresence(this._channels.spectatorsChannel, anearUser.id)
  }

  subscribeSpectatorCssMessages = callback => {
    // need to refactor so Spectators have a private channel
    //this._userMessaging.subscribeEventMessages(this._channels.spectatorsChannel, CssDisplayMessageType, callback, true)
  }

  unSubscribeEventBroadcastMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, BroadcastMessageType)
  }

  unSubscribeEventTransitionMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, EventTransitionMessageType)
  }

  unSubscribeEventPlayAgainMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, PlayAgainMessageType)
  }

  closeSpectatorsChannel = async () => {
    if (!this._channels.spectatorsChannel) return 

    this._userMessaging.unSubscribeEventMessages(this._channels.spectatorsChannel, PublicDisplayMessageType)
    this._userMessaging.unSubscribeEventMessages(this._channels.spectatorsChannel, CssDisplayMessageType)
    await this._userMessaging.detachChannel(this._channels.spectatorsChannel)
    this._channels.spectatorsChannel = null
  }
}
