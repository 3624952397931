import PlayableEvent from '../Models/PlayableEvent'
import AnearEvent from '../Models/AnearEvent'
import AnearParticipant from '../Models/AnearParticipant'
import AnearGuestAvatar from '../Models/AnearGuestAvatar'
import PlayableZone from '../Models/PlayableZone'
import PlayableApp from '../Models/PlayableApp'
import ApiService from './ApiService'

class AnearApi extends ApiService {

  login = async (user, password) => {
    const json = await this.post("sessions", {user, password}, false)
    return this.createAnearUser(json)
  }

  register = async (name, email, password) => {
    const json = await this.post("registrations", {name, email, password}, false)
    return this.createAnearUser(json)
  }

  getAccount = async () => {
    const json = await this.get("accounts", {}, false)
    return this.createAnearUser(json)
  }

  updateGuestAccount = async (id, name, guestAvatarKey) => {
    const json = await this.put(
      "accounts", id,
      {
        name: name,
        'guest-avatar-key': guestAvatarKey
      },
      false
    )
    return this.createAnearUser(json)
  }

  dataURItoBlob = (dataURI) => {
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  updateAvatarImage = async (user, dataURI) => {
    const blob = this.dataURItoBlob(dataURI)
    const profileId = user.profile.id
    const imageBlob = await this.activeStorageUpload(user.name(), blob)

    const json = await this.put("profiles", profileId, { avatar: imageBlob.signed_id}, false)
    return this.createAnearUser(json)
  }

  updateProfile = async (id, firstName, lastName, phone, homepage, bio) => {
    const json = await this.put(
      "profiles",
      id,
      {
        'first-name': firstName,
        'last-name': lastName,
        phone,
        homepage,
        bio,
      },
      false
    )
    return this.createAnearUser(json)
  }

  globalPlayableApps = async (filter={}) => {
    const json = await this.get("global_apps", {filter: filter}, false)
    return json.data.map(
      app => new PlayableApp(app, json.included)
    )
  }

  playableEvents = async (filter) => {
    const json = await this.get("playable_events", {filter: filter}, true)
    return json.data.map(
      anearEvent => new PlayableEvent(anearEvent, json.included)
    )
  }

  localZones = async (filter) => {
    const json = await this.get("local_zones", {filter: filter}, true)
    return json.data.map(
      zone => new PlayableZone(zone, json.included)
    )
  }

  createEvent = async (zoneId, {name, description, participationRadius}) => {
    const relationships = {zone: zoneId}
    const needsLocation = participationRadius && participationRadius > 0
    let flags = []
    const json = await this.post("events", {name, description, flags}, needsLocation, relationships)
    return new AnearEvent(json.data, json.included)
  }

  cloneEvent = async (anearEvent) => {
    const eventId = anearEvent.id
    const needsLocation = anearEvent.requiresGeoLocationToParticipate()
    const resource = `/events/${eventId}/clone`
    const json = await this.post(resource, {}, needsLocation, {})
    return new AnearEvent(json.data, json.included)
  }

  becomeEventParticipant = async (anearEvent) => {
    const relationships = {event: anearEvent.id}
    const json = await this.post(
      "event_participants",
      {},
      anearEvent.requiresGeoLocationToParticipate(),
      relationships
    )
    return new AnearParticipant(json.data, json.included)
  }

  getEventParticipant = async (eventId) => {
    const json = await this.get("event_participants", {id: eventId}, false)
    return new AnearParticipant(json.data, json.included)
  }

  getEvent = async (eventIdOrSlug) => {
    const json = await this.get("events", {id: eventIdOrSlug}, false)
    return new AnearEvent(json.data, json.included)
  }

  getGuestAvatarIndex = async () => {
    const json = await this.get("guest_avatars", {}, false)
    return new AnearGuestAvatar(json.data, json.included)
  }
}

const anearApi = new AnearApi()

export default anearApi
