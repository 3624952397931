import Playable from './Playable'
import AnearEvent from './AnearEvent'

export default class PlayableEvent extends Playable {

  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.anearEvent = new AnearEvent(this.findIncluded(this.relationships['event']), json_included)
  }
}
