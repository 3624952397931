import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'

import ScanQRIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import TodayIcon from '@mui/icons-material/Today'
import NearMeIcon from '@mui/icons-material/NearMe'
import AppsIcon from '@mui/icons-material/Apps'
import AccountCircle from '@mui/icons-material/AccountCircle'
import ChatIcon from '@mui/icons-material/Chat'

import CurrentUserAvatar from './CurrentUserAvatar'
import UIStateContainer from '../Utils/UIStateContainer'
import UserAuthContainer from '../Utils/UserAuthContainer'

const LinkStyling = {
  textDecoration: 'none'
}

const BurgerMenu = () => {
  const menuLink = (ComponentIcon, label, route) => (
    <Box component={Link} to={route} key={label} sx={LinkStyling}>
      <ListItem button>
        <ListItemIcon>
          <ComponentIcon color="secondary"/>
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </Box>
  )

  const accountInfo = name => (
    <>
      <ListItemIcon>
        <CurrentUserAvatar size={'mediumAvatar'} />
      </ListItemIcon>
      <ListItemText primary={name} />
    </>
  )

  const unauthdInfo = () => (
    <>
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText primary={'Guest'} />
    </>
  )

  const getProfileRoute = userIsAuthed => {
    return userIsAuthed ? '/profile' : '/login'
  }

  const userProfileItem = (userIsAuthed, userName) => {
    const route = getProfileRoute(userIsAuthed)

    return (
      <Box component={Link} to={route} key={"profile"} sx={LinkStyling}>
        <ListItem button>
          {
            userIsAuthed ? accountInfo(userName) : unauthdInfo()
          }
        </ListItem>
      </Box>
    )
  }

  const menuRows = [
    [ScanQRIcon, "Scan QR",       "/scan"],
    [TodayIcon,  "My Events",     "/events"],
    [NearMeIcon, "What's Nearby", "/nearby"],
    [AppsIcon,   "Apps",          "/apps"],
    [ChatIcon,   "Chat",          "/chat"],
  ]

  const aboutRow = [QuestionAnswerIcon, "About", "/about"]

  const menuItems = (
    <Box sx={{width: 250}}>
      <List>
        {menuRows.map(menuRow => {
          return menuLink(...menuRow)
        })}
      </List>
      <Divider />
      <List>
        {menuLink(...aboutRow)}
      </List>
    </Box>
  )

  const uiContainer = UIStateContainer.useContainer()
  const userAuthContainer = UserAuthContainer.useContainer()

  const userIsAuthed = userAuthContainer.isAuthed()

  return (
    <Drawer open={uiContainer.isBurgerMenuOpen} onClose={uiContainer.closeBurgerMenu} >
      <Box
        tabIndex={0}
        role="button"
        onClick={uiContainer.closeBurgerMenu}
        onKeyDown={uiContainer.closeBurgerMenu}
      >
        { userProfileItem(userIsAuthed, userAuthContainer.name()) }
        <Divider />
        {menuItems}
      </Box>
    </Drawer>
  )
}

export default BurgerMenu
