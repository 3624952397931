export default class ErrorResponse {

  constructor(json) {
    this.json_errors = json.errors[0]
    this.error = json.errors[0]
    this.hasSource = this.error.hasOwnProperty('source')
    this.source = this.hasSource ? this.getSource() : null
    this.message = this.formatMessage()
  }

  getSource = () => {
    const { source } = this.error
    if (source.parameter) {
      return source.parameter
    } else {
      const [attrName] = this.error.source.pointer.split("/").slice(-1)
      return attrName
    }
  }

  formatMessage = () => {
    return this.error.detail
  }
}
