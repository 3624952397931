import { useEffect } from 'react'
import UserAuthContainer from '../Utils/UserAuthContainer'
import UIStateContainer from '../Utils/UIStateContainer'
import * as logger from 'loglevel'

const useLoadUser = () => {
  const userAuth = UserAuthContainer.useContainer()
  const uiState = UIStateContainer.useContainer()

  useEffect(() => {
    const loadUser = async () => {
      try {
        if (!userAuth.isAuthed()) {
          uiState.startProgress()
          await userAuth.loadUser()
          logger.info('User authentication loaded')
        }
      } catch (err) {
        logger.error('Failed to load user authentication:', err)
      } finally {
        uiState.progressComplete()
      }
    }

    loadUser()
  }, [userAuth, uiState])

  return userAuth
}

export default useLoadUser
