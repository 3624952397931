import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EventCardHeader from './EventCardHeader'

const styles = theme => ({
  card: {
    display: 'flex',
    maxWidth: 345,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
});

const ParticipantEventCard = ({playableEvent, classes}) => (
  <Card className={classes.card} elevation={3}>
    <EventCardHeader
       anearEvent={playableEvent.anearEvent}
       createdAt={playableEvent.anearEvent.attributes['created-at']}
       distance={playableEvent.distanceTo}
    />
    <div className={classes.details}>
      <CardContent className={classes.content}>
        <Typography variant="subtitle2" color="textSecondary">
          {playableEvent.anearEvent.attributes.description}
        </Typography>
      </CardContent>
    </div>
  </Card>
)

const OpenEventCard = ({playableEvent, classes}) => {
  return <Card className={classes.card} elevation={3}>
    <EventCardHeader
       anearEvent={playableEvent.anearEvent}
       createdAt={playableEvent.anearEvent.attributes['created-at']}
       distance={playableEvent.distanceTo}
    />
    <div className={classes.details}>
      <CardContent className={classes.content}>
        <Typography variant="subtitle2" color="textSecondary">
          {playableEvent.anearEvent.attributes.description}
        </Typography>
      </CardContent>
    </div>
  </Card>
}


class EventCard extends React.Component {

  renderSwitch = (playableEvent) => {
    const { classes } = this.props

    switch(playableEvent.playableState) {
      case 'participant':
      case 'creator':
      case 'spectator':
        return <ParticipantEventCard playableEvent={playableEvent} classes={classes}/>
      case 'open':
      case 'open_house':
      case 'public':
        return <OpenEventCard playableEvent={playableEvent} classes={classes} />
      default:
        return null
    }
  }

  render() {
    const { playableEvent } = this.props

    return this.renderSwitch(playableEvent)
  }
}

EventCard.propTypes = {
  playableEvent: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(EventCard)
