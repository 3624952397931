import JsonApiResource from './JsonApiResource'

export default class AnearZone extends JsonApiResource {

  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.app = this.findIncluded(this.relationships['app'])
  }

  get maxParticipantRadius() {
    return this.attributes['max-participation-radius']
  }

  get canPlayAgain() {
    return this.app.attributes.flags.includes('play_again')
  }
}
